import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

import { medias } from '../utils/mediaQueries';

import { Button } from '.';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;

  @media ${medias.phoneMax} {
    flex-direction: column;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27%;

  @media ${medias.tabletMin} {
    width: 40%;
  }

  @media ${medias.phoneMax} {
    width: 100%;
  }
`;

const ItemTitle = styled.h2`
  color: ${theme.colors.secondary};
  font-weight: bold;
  text-align: center;
  margin: ${props => (props.longTitle ? '12px 0 0 0' : '20px 0 20px 0')};
`;

const ItemTitle3 = styled.h3`
  color: ${theme.colors.secondary};
  font-weight: bold;
  text-align: center;
  margin: ${props => (props.longTitle ? '12px 0 0 0' : '20px 0 20px 0')};
`;

const ItemText = styled.p`
  font-size: ${theme.fontSizeLg * 0.9}px;
  text-align: center;
  margin: ${props => (props.longTitle ? '12px 0 18px 0' : '18px 0')};
`;

const LinkA = styled.a`
  text-decoration: none;
`;

const ItemText3 = styled.span`
  font-size: ${theme.fontSizeLg * 0.9}px;
  text-align: justify;
  margin: ${props => (props.longTitle ? '12px 0 18px 0' : '18px 0')};
`;

const StyledButton = styled(props => <Button {...props} />)`
  margin: 0 10px;
  display: flex;
  width: 100px;

  @media ${medias.tabletMin} {
    width: 270px;
    font-size: 13px;
  }

  @media ${medias.phoneMax} {
    width: 300px;
  }
`;

const Indicators = () => {
  return (
    <Wrapper>
      <ItemTitle longTitle id="indicadores">
        Conheça os Indicadores Avaliados
      </ItemTitle>
      <ItemText longTitle>
        Avaliamos 16 itens para certificação de fornecedores
      </ItemText>

      <Row>
        <ItemContainer>
          <ItemTitle3 longTitle>JURÍDICOS/FINANCEIROS</ItemTitle3>
          <ItemText3 longTitle>
            <ul style={{ listStyle: 'none' }}>
              <li>
                <b>1</b> Situação cadastral na Receita Federal
                <br />
                Mínimo 2 anos de CNPJ, exceto Buffet que necessita de pelo menos
                5 anos
              </li>
              <li>
                <b>2</b> Requisitos extras para segmentos específicos
                <br />
                - Para Espaços: Auto de Vistoria do Corpo de Bombeiros (AVCB)
                <br />- Para Buffet: Alvará da vigilância sanitária
              </li>
              <li>
                <b>3</b> Falência / Concordata / Recuperação Judicial
              </li>
              <li>
                <b>4</b> Participação dos sócios em outras empresas
              </li>
              <li>
                <b>5</b> Participação em empresa falida
              </li>
              <li>
                <b>6</b> Pontualidade de pagamentos
              </li>
              <li>
                <b>7</b> Score minimo serasa 90
              </li>
              <li>
                <b>8</b> Dívidas vencidas / Restrições de crédito / Pendências
              </li>
              <li>
                <b>9</b> Protestos nacionais
              </li>
              <li>
                <b>10</b> Cheques sem fundos
              </li>
              <li>
                <b>11</b> Processos Judiciais (Trabalhista, Civil e Federal)
              </li>
              <li>
                <b>12</b> Débito Fiscal Federal
              </li>
              <li>
                <b>13</b> Débito de INSS
              </li>
              <li>
                <b>14</b> Empresa Inidônea e/ou Suspensa de Licitar
              </li>
              <li>
                <b>15</b> Devedor trabalhista
              </li>
              <li>
                <b>16</b> Sites de Reclamação (Ex.: Reclame Aqui, ZankYou,
                Google, com pelo menos 80% de aceitação)
              </li>
            </ul>
          </ItemText3>
          <LinkA to="/junte-se">
            <StyledButton text="SEJA CERTIFICADO" />
          </LinkA>
          <br />
        </ItemContainer>
      </Row>
    </Wrapper>
  );
};

export default Indicators;
